import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import WhatsApp from "../components/WhatsApp";
import Instagram from "../components/Instagram";
import Faqs from "../components/Faqs";

// eslint-disable-next-line
export const AboutPageTemplate = ({
  title,
  description,
  image,
  content,
  contentComponent,
  locale,
  faqs,
  faqTitle,
}) => {
  const PostContent = contentComponent || Content;
  return (
    <section className="subPage">
      <div className="image">
        <Img
          className="post-image"
          fluid={image.childImageSharp.fluid}
          alt={title}
          title={title}
        />
        <div className="titles">
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
      </div>
      <div className="container">
        <div className="main">
          <div className="content">
            <PostContent content={content} />
            <div className="dark">
              <WhatsApp locale={locale} />
              <Instagram locale={locale} />
            </div>
            <h3>{faqTitle}</h3>
            <div className="content">
              <Faqs gridItems={faqs.faq} filter="all" items="10" />
            </div>
          </div>
        </div>
        <div className="aside">
          <img src={"/img/dr.jpg"} alt={title} className="dr" />
        </div>
      </div>
    </section>
  );
};

AboutPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  contentComponent: PropTypes.func,
  faqTitle: PropTypes.string,
  faqs: PropTypes.shape({
    faq: PropTypes.array,
  }),
};

const AboutPage = ({ pageContext: { locale }, ...props }) => {
  const { node: data } = props.data.AboutPageData.edges[0];
  const { node: faq } = props.data.FaqPageData.edges[0];
  return (
    <Layout
      locale={locale}
      title={data.frontmatter.title}
      description={data.frontmatter.description}
    >
      <Helmet>
        <title>{data.frontmatter.title}</title>
        <meta name="description" content={`${data.frontmatter.description}`} />
      </Helmet>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        image={data.frontmatter.image}
        title={data.frontmatter.title}
        description={data.frontmatter.description}
        content={data.html}
        locale={locale}
        faqs={faq.frontmatter.faqs}
        faqTitle={faq.frontmatter.title}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
  faq: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};
export default AboutPage;

export const aboutPageQuery = graphql`
  query aboutPageQuery($locale: String) {
    AboutPageData: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "about-page" }
          locale: { eq: $locale }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          html
          frontmatter {
            title
            description
            locale
            image {
              childImageSharp {
                fluid(maxWidth: 1600, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    FaqPageData: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "faq-page" }
          locale: { eq: $locale }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          html
          frontmatter {
            title
            description
            faqs {
              faq {
                category
                question
                answer
              }
            }
            locale
            image {
              childImageSharp {
                fluid(maxWidth: 1600, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
